import {
  formatDateWithTime,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';
import { number2mxn } from 'utils/currency';
import moment from 'moment';

const transformer = (values) => ({
  series: values.serie,
  folio: values.folio,
  rfc: values.rfc,
  customerName: values.customerFullName,
  invoiceDate: moment(values.dateTime).format(YYYY_MM_DD),
  vat: values.vat,
  amount: number2mxn(values.amount),
  totalAmount: values.totalAmount,
  invoiceStatus: values.invoiceStatus.name,
  error: JSON.parse(values.response).Descripcion,
});

export default transformer;
