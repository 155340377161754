import _get from 'lodash/get';
import 'moment/locale/es';
import store from 'store';

const formatCash = ({ amount, amountCash, contractNumber, diffMethods }) => ({
  paymentForm: 'CASH',
  reservation: '',
  paymentType: 'PAYMENT',
  totalAmount: diffMethods ? Number(amountCash) : Number(amount),
  contractNumber,
});

// formato para el envio de los datos de tarjeta de credito o debito
const formatCard = ({
  cardType,
  bank,
  cardNumber,
  reference,
  authorization,
  paymentDate,
  paymentTime,
  voucher,
  amount,
  amountCard,
  contractNumber,
  hasHolderName,
  accountStatus,
  diffMethods,
}) => {
  const cardTypes = _get(
    store.getState(),
    'commons.catalogsBankCardTypes.list',
    []
  );

  const fullCardType = cardTypes.find((card) => card.uuid === cardType);

  const cardDetails = {
    bank,
    bankAuthorization: authorization,
    bankCardType: cardType,
    cardHasCustomerName: hasHolderName,
    cardNumber,
    referenceNumber: reference,
  };

  const paymentForm = `${fullCardType?.identifier}_CARD`;

  return {
    paymentForm,
    cardDetails,
    paymentType: 'PAYMENT',
    paymentDate,
    paymentTime,
    files: {
      paymentProof: voucher,
      accountStatus: !hasHolderName ? accountStatus : null,
    },
    totalAmount: diffMethods ? Number(amountCard) : Number(amount),
    contractNumber,
  };
};

// formato para el envio de los datos de deposito
const formatDeposit = ({
  bankAuthorization,
  paymentDate,
  paymentTime,
  bankVoucher,
  amountDeposit,
  amount,
  contractNumber,
  diffMethods,
}) => {
  return {
    paymentForm: 'DEPOSIT',
    paymentType: 'PAYMENT',
    depositDetails: {
      bankAuthorization,
    },
    paymentDate,
    paymentTime,
    files: {
      paymentProof: bankVoucher,
    },
    totalAmount: diffMethods ? Number(amountDeposit) : Number(amount),
    contractNumber,
  };
};

// formato para el envio de los datos de transferencia bancaria
const formatTransfer = ({
  bankTo,
  bankClave,
  bankReference,
  paymentDate,
  paymentTime,
  transferVoucher,
  amountTransfer,
  amount,
  contractNumber,
  diffMethods,
}) => {
  const transferDetails = {
    bank: bankTo,
    referenceNumber: bankReference,
    trackingKey: bankClave,
  };

  return {
    paymentForm: 'TRANSFER',
    paymentType: 'PAYMENT',
    transferDetails: transferDetails,
    paymentDate,
    paymentTime,
    files: {
      paymentProof: transferVoucher,
    },
    totalAmount: diffMethods ? Number(amountTransfer) : Number(amount),
    contractNumber,
  };
};

// formato para invoice
export const formatInvoice = ({
  invoiceSocialReason,
  invoiceRfc,
  invoiceCfdiUse,
  invoicePayForm,
  invoicePayMethod,
  emailInvoice,
  observationsInvoice,
  invoiceSelected,
  zipCode,
  invoiceTaxRegime,
  invoiceDeleted,
}) => ({
  invoiceForm: {
    invoiceSocialReason,
    invoiceRfc,
    invoiceCfdiUse,
    emailInvoice,
    invoicePayForm,
    invoicePayMethod,
    observationsInvoice,
    invoiceSelected,
    zipCode,
    invoiceTaxRegime,
  },
  invoiceDeleted,
});

const formatter = (values) => {
  let request = [];
  let invoiceData = [];
  const multiplesPayment = [];
  if (values.diffMethods) {
    if (values.amountCard) multiplesPayment.push(formatCard(values));
    if (values.amountDeposit) multiplesPayment.push(formatDeposit(values));
    if (values.amountTransfer) multiplesPayment.push(formatTransfer(values));
    if (values.amountCash) multiplesPayment.push(formatCash(values));
    for (let i = 0; i < multiplesPayment.length; i++) {
      // for (let j = 0; j < multiplesPayment[i].length; j++) {
      // request.push(multiplesPayment[i][j]);
      // }
      multiplesPayment[i].discount = 0;
      request.push(multiplesPayment[i]);
    }
    multiplesPayment[multiplesPayment.length - 1].discount = values.discount;
  } else {
    switch (values.methodSelected) {
      case 'card':
        request = formatCard(values);
        break;
      case 'deposit':
        request = formatDeposit(values);
        break;
      case 'transfer':
        request = formatTransfer(values);
        break;
      case 'cash':
        request = formatCash(values);
        break;
      default:
        break;
    }
    request.discount = values.discount;
  }

  if (values.wantInvoice) {
    invoiceData = formatInvoice(values);
    return {
      invoiceForm: invoiceData.invoiceForm,
      invoiceDeleted: invoiceData.invoiceDeleted,
      contract: values.contractUuid,
      customerUuid: values.customerUuid,
      branch: values.branch,
      movementType: values.movementType,
      amount: Number(values.amount),
      concept: values.concept,
      remarks: values.observations,
      reason: values.reason,
      applicantUser: values.requester,
      error: values.error,
      validCodeRequest: {
        username: '',
        code: '',
      },
      methodPayment: request,
    };
  }

  return {
    contract: values.contractUuid,
    customerUuid: values.customerUuid,
    branch: values.branch,
    movementType: values.movementType,
    amount: Number(values.amount),
    concept: values.concept,
    remarks: values.observations,
    reason: values.reason,
    applicantUser: values.requester,
    error: values.error,
    methodPayment: request,
    validCodeRequest: {
      username: '',
      code: '',
    },
  };
};

export default formatter;
