import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _template from 'lodash/template';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';
import { useFormikContext, validateYupSchema } from 'formik';
import { useParams, useLocation } from 'react-router-dom';

import {
  MaskedInput as Input,
  DatePickerV2,
  RadioGroupInput,
  Icons,
  Button,
  ConnectedSelect,
  Section,
  ActionModal,
  useFirstRender,
} from '@casanova/casanova-common';
import {
  alphaMask,
  numericMask,
  alphaEmailMask,
} from '@casanova/casanova-common/lib/utils/masks';
import { getPrevYear } from '@casanova/casanova-common/lib/utils/date';

import { CUSTOMER_TYPES } from '@customers/common/constants';
import {
  isBanned,
  validateContactInfo,
  isJuridicalCustomer as isJuridical,
  hasBirthInForeign,
} from '@customers/common/utils';

import i18n from '@i18n';

import { FORM_ACTIONS } from 'utils/constants/formActions';
import { EXTERNAL_LINKS } from 'utils/constants/externalLinks';
import { isContractQuote, QUOTE_CONTRACT_QUERY } from 'utils/navigation';

import { CurpSchema, EmailSchema, PhoneSchema } from './schema';
import { VALUES_LIST, VALUES_LIST_TENDER } from './constants';
import { alphaMaskWithSpacesAndAccent } from '../../../../utils/masks';

const { GOB_CURP } = EXTERNAL_LINKS;
const { VIEW, ADD, EDIT } = FORM_ACTIONS;

const CustomerInformation = ({
  history,
  action,
  minimal,
  sectionClass,
  validateCustomerCurp,
  validateEndorsementCurp,
  curpInfo,
  endorsementCurpInfo,
  nextPath,
  preventBlock,
  postAvailableUser,
  availableUser,
  cleanValidateCurp: cleanValidateCustomerCurp,
  cleanValidateEndorsementCurp,
  isReservation = false,
  assignCustomer,
  setCustomerType,
  customerType,
  tenderType,
  setTenderType,
  setCustomerPersonType,
  customerPersonType,
  isJuridicalCustomer,
  onlyFisic = false,
  onModalOpen = _noop,
  onModalClose = _noop,
  avalCustomer = false,
  getDetail = _noop,
  customerDetail,
  resetDetail,
  resetAvalCustomer,
  isForeigner: customerForeigner = false,
  setFormValues = _noop,
  customerFormValues = {},
  setAvalPreventChange = _noop,
  setSelectionOfAvalCustomerByDetail = _noop,
  selectionOfAvalCustomerByDetail = false,
  setAvalCustomerByDetail = _noop,
  loader = false,
  isTender = false,
}) => {
  const initialUnavailableCustomersModal = !avalCustomer;

  const [detailModal, setDetailModal] = useState(false);
  const [showRegisteredUserModal, setShowRegisteredUserModal] = useState(false);
  const [showBannedModal, setShowBannedModal] = useState(false);
  const [
    showUnavailableJuridicalCustomer,
    setShowUnavailableJuridicalCustomer,
  ] = useState(false);
  const [
    availableOpenCustomersModal,
    setAvailableOpenCustomersModal,
  ] = useState(initialUnavailableCustomersModal);
  // const [
  //   stillTender,
  //   setStillTender,
  // ] = useState(false);

  const {
    values,
    initialValues,
    initialTouched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    setValues,
    setErrors,
    errors,
    submitCount,
  } = useFormikContext();

  const { personType = '' } = values;
  const { id } = useParams();
  const location = useLocation();

  const hasContract = isContractQuote();

  const { isValidating = false, isValid = false, data = {} } = avalCustomer
    ? endorsementCurpInfo
    : curpInfo;
  const validateCurp = avalCustomer
    ? validateEndorsementCurp
    : validateCustomerCurp;
  const cleanValidateCurp = avalCustomer
    ? cleanValidateEndorsementCurp
    : cleanValidateCustomerCurp;

  const getDetailPath = useMemo(
    () =>
      _template(hasContract ? `${nextPath}?${QUOTE_CONTRACT_QUERY}` : nextPath),
    [nextPath, hasContract]
  );

  const isForeigner = useMemo(
    () => customerForeigner || customerType === CUSTOMER_TYPES.FOREGINER,
    [customerType, customerForeigner]
  );

  const firstRender = useFirstRender();

  const handleValidateCurp = () => {
    setAvailableOpenCustomersModal(initialUnavailableCustomersModal);
    setFieldTouched('curp', true);
    validateYupSchema({ curp: values.curp }, CurpSchema)
      .then(() => {
        validateCurp(values.curp);
      })
      .catch((errorsInner) => {
        setErrors({ ...errors, curp: errorsInner.errors[0] });
      });
  };

  // const handleValidateEmail = (evt) => {
  //   const email = evt.target.value;

  //   setFieldTouched('email', true);
  //   validateYupSchema({ email }, EmailSchema)
  //     .then(() => {
  //       postAvailableUser({ field: 'email', value: email, naturalPerson: id });
  //     })
  //     .catch((errorsInner) => {
  //       setErrors({ ...errors, email: errorsInner.errors[0] });
  //     });
  // };

  // const handleValidateCellphone = (evt) => {
  //   const cellphone = evt.target.value;

  //   setFieldTouched('phoneNumber', true);
  //   validateYupSchema({ phoneNumber: cellphone }, PhoneSchema)
  //     .then(() => {
  //       postAvailableUser({
  //         field: 'cellphone',
  //         value: cellphone,
  //         naturalPerson: id,
  //       });
  //     })
  //     .catch((errorsInner) => {
  //       setErrors({ ...errors, phoneNumber: errorsInner.errors[0] });
  //     });
  // };

  const handleOnChangeCustomerSector = useCallback(
    (event) => {
      setValues({
        ...values,
        customerSector: event.target.value,
      });
    },
    [values, setValues]
  );

  const resetCurpValues = () => {
    const resetsValues = {
      curp: '',
      names: '',
      lastName: '',
      secondLastName: '',
      birthDate: '',
      gender: '',
      birthPlace: '',
    };
    setValues({ ...values, ...resetsValues });
    cleanValidateCurp();
  };

  const resetCurp = () => {
    setFieldValue('curp', '');
    setFieldTouched('curp', false);
    cleanValidateCurp();
  };

  const handleCloseDetailModal = () => {
    if (preventBlock) preventBlock(true);
    setDetailModal(false);
    resetCurp();
  };

  const handleActionDetailsModal = () => {
    if (avalCustomer) {
      setSelectionOfAvalCustomerByDetail(true);
      setAvalPreventChange(false);
    }

    if (preventBlock) preventBlock(false);
    setDetailModal(false);

    if (isReservation && !avalCustomer) {
      assignCustomer(data.naturalPersonFound);
    }
    resetCurp();
    setTimeout(() => {
      const path = getDetailPath({ id: data.naturalPersonFound });
      history.push(path);
    }, 1);
  };

  const handleActionRegisteredUser = () => {
    setShowRegisteredUserModal(false);
    resetCurpValues();
  };

  const handleActionBannedCustomer = () => {
    setShowBannedModal(false);
    resetCurpValues();
  };

  const handleActionUnavailableJuridicalCustomer = () => {
    setShowUnavailableJuridicalCustomer(false);
    resetCurpValues();
  };

  const handleClickNational = () => {
    setCustomerType(CUSTOMER_TYPES.NATIONAL);
    setCustomerPersonType(CUSTOMER_TYPES.FISIC);
    setFieldValue('personType', CUSTOMER_TYPES.FISIC);
  };

  const handleClickForeigner = () => {
    setCustomerType(CUSTOMER_TYPES.FOREGINER);
    setCustomerPersonType('');
    setFieldValue('personType', '');
  };

  const handleRadioChange = ({ value }) => {
    // setStillTender(false);
    setCustomerPersonType(value);
  };

  const handleRadioTenderChange = ({ value }) => {
    // setCustomerPersonType(value);
    // setStillTender(true);
    setTenderType(value);
  };

  useEffect(
    () => () => {
      if (!avalCustomer && selectionOfAvalCustomerByDetail) {
        setFormValues(values);
      }
    },
    [avalCustomer, selectionOfAvalCustomerByDetail, values, setFormValues]
  );

  useEffect(() => {
    if (
      selectionOfAvalCustomerByDetail &&
      !_isEmpty(customerFormValues) &&
      !avalCustomer &&
      firstRender
    ) {
      setValues(customerFormValues);
      setAvalPreventChange(true);
      setSelectionOfAvalCustomerByDetail(false);
    }
  }, [
    selectionOfAvalCustomerByDetail,
    customerFormValues,
    setValues,
    avalCustomer,
    firstRender,
  ]);

  useEffect(() => {
    setFieldValue('customerType', customerType);
  }, [customerType]);

  useEffect(() => {
    if (customerDetail?.data?.tender?.tenderType)
      setTenderType(customerDetail?.data?.tender?.tenderType);
  }, [customerDetail?.data?.tender?.tenderType]);

  useEffect(() => {
    if (customerDetail.success && (isReservation || avalCustomer)) {
      const { customerStatus = {} } = customerDetail.data;

      if (avalCustomer) {
        // eslint-disable-next-line no-shadow
        const isJuridicalCustomer = isJuridical(customerDetail.data);
        setAvailableOpenCustomersModal(!isJuridicalCustomer);
        setShowUnavailableJuridicalCustomer(isJuridicalCustomer);
      } else {
        const bannedCustomer = isBanned(customerStatus);
        setAvailableOpenCustomersModal(!bannedCustomer);
        setShowBannedModal(bannedCustomer);
      }
    }
  }, [customerDetail, isReservation, avalCustomer]);

  useEffect(() => {
    if (avalCustomer) {
      if (
        (detailModal && customerDetail.success) ||
        showRegisteredUserModal ||
        showUnavailableJuridicalCustomer
      )
        onModalOpen();
      else onModalClose();
    }
  }, [
    customerDetail.success,
    avalCustomer,
    detailModal,
    showRegisteredUserModal,
    showUnavailableJuridicalCustomer,
  ]);

  // useEffect(() => {
  //   if (values.email) {
  //     const isUnique = validateContactInfo(availableUser, 'email');
  //     setFieldValue('isUniqueEmail', isUnique);
  //   } else {
  //     setFieldValue('isUniqueEmail', true);
  //   }
  // }, [availableUser, setFieldValue, values.email]);

  // useEffect(() => {
  //   if (values.phoneNumber) {
  //     const isUnique = validateContactInfo(availableUser, 'cellphone');
  //     setFieldValue('isUniquePhone', isUnique);
  //   } else {
  //     setFieldValue('isUniquePhone', true);
  //   }
  // }, [availableUser, setFieldValue, values.phoneNumber]);

  useEffect(() => {
    if (action === ADD) {
      const exists = !isValidating && isValid && !_isEmpty(data);

      if (exists && !data.naturalPersonFound) {
        const {
          names,
          lastName,
          secondLastName,
          sex,
          birthState,
          birthDate,
        } = data;

        const customerBirthInForeign = hasBirthInForeign(data);

        setFieldValue('names', names);
        setFieldValue('lastName', lastName);
        setFieldValue('secondLastName', secondLastName);
        setFieldValue('birthDate', birthDate);
        setFieldValue('gender', _get(sex, 'uuid', ''));
        setFieldValue(
          'birthPlace',
          customerBirthInForeign
            ? _get(data, 'originCountry.name', '')
            : _get(birthState, 'name', '')
        );
        if (customerBirthInForeign || Boolean(birthDate)) {
          setTimeout(() => {
            setFieldTouched('birthDate', true);
          }, 1);
        }
        if (preventBlock) preventBlock(true);
      }

      const { naturalPersonFound, userFound } = data;

      const unavailableCurp = naturalPersonFound || userFound;
      const withUnavailableCurp =
        naturalPersonFound && unavailableCurp === naturalPersonFound;

      if (
        exists &&
        withUnavailableCurp &&
        (isReservation || avalCustomer) &&
        !availableOpenCustomersModal
      ) {
        getDetail(naturalPersonFound);
      }

      if (exists && isValid && unavailableCurp && (!isForeigner || onlyFisic)) {
        if (preventBlock) preventBlock(false);

        if (unavailableCurp === userFound) {
          setShowRegisteredUserModal(true);
        } else {
          setDetailModal(true);
        }
      }
    }
  }, [
    curpInfo,
    endorsementCurpInfo,
    setFieldValue,
    setFieldTouched,
    preventBlock,
    availableOpenCustomersModal,
    getDetail,
    isReservation,
    avalCustomer,
  ]);

  useEffect(() => {
    if (submitCount > 0 && personType) {
      setTouched(initialTouched);
    }
  }, [submitCount, personType, initialTouched, setTouched]);

  useEffect(
    () => () => {
      // Verifica si la ruta actual termina con '/edit'
      const isEdition = location.pathname.endsWith('/edit');
      if (!isEdition) resetDetail();

      if (!avalCustomer) {
        cleanValidateCurp();
        resetAvalCustomer();
        setCustomerType(CUSTOMER_TYPES.NATIONAL);
        setCustomerPersonType(CUSTOMER_TYPES.FISIC);
        setAvalCustomerByDetail({});
      } else {
        cleanValidateEndorsementCurp();
      }
    },
    [
      cleanValidateCurp,
      setCustomerType,
      setCustomerPersonType,
      resetDetail,
      resetAvalCustomer,
      avalCustomer,
      cleanValidateEndorsementCurp,
      setAvalCustomerByDetail,
    ]
  );

  const isView = action === VIEW;

  const radioRender = (
    <div className="col-12 switch-inverted">
      <RadioGroupInput
        labelClass="d-flex align-items-center"
        groupClass="col-12 d-flex justify-content-between"
        parentClass="vehicle-radio-input"
        label=""
        name="personType"
        options={VALUES_LIST}
        disabled={isView}
        standardError
        onChange={handleRadioChange}
      />
    </div>
  );

  const disabledForeigner = useMemo(
    () => (isTender ? false : !isForeigner || onlyFisic),
    [isForeigner, onlyFisic, customerPersonType]
  );

  const disabledInput = useMemo(() => isView || disabledForeigner, [
    action,
    disabledForeigner,
  ]);

  const title = useMemo(
    () =>
      avalCustomer
        ? 'INFORMACIÓN DE AVAL'
        : isJuridicalCustomer && !isForeigner
        ? i18n('CUSTOMERS__SECTION__INFO')
        : isTender
        ? 'INFORMACIÓN DE CONTACTO'
        : i18n('CUSTOMERS__SECTION__TITLE'),
    [avalCustomer, isJuridicalCustomer, isForeigner, isTender]
  );

  const requiredField = useMemo(() => (isForeigner || isTender ? '*' : ''), [
    isForeigner,
    customerPersonType,
  ]);

  const originCountryLabel = useMemo(
    () =>
      isForeigner && !avalCustomer ? 'PAÍS DE ORÍGEN*' : 'Lugar de nacimiento',
    [avalCustomer, isForeigner]
  );

  const originCountryName = useMemo(
    () => (isForeigner && !avalCustomer ? 'originCountry' : 'birthPlace'),
    [avalCustomer, isForeigner]
  );

  const originCountryOptions = useMemo(
    () =>
      isForeigner && !avalCustomer ? 'commons.countries' : 'commons.states',
    [avalCustomer, isForeigner]
  );

  return (
    <>
      <ActionModal
        open={detailModal && !loader}
        icon={<Icons.UserAlert />}
        closeButton
        onClose={handleCloseDetailModal}
        onAction={handleActionDetailsModal}
        modalType="inverted"
        title={i18n('CUSTOMERS__EXISTING_CUSTOMER__MODAL__TITLE')}
        actionLabel={i18n('CUSTOMERS__EXISTING_CUSTOMER__MODAL__ACTION__LABEL')}
        closeLabel={i18n('CUSTOMERS__EXISTING_CUSTOMER__MODAL__CLOSE__LABEL')}
        message={i18n('CUSTOMERS__EXISTING_CUSTOMER__MODAL__MESSAGE')}
      />

      <ActionModal
        open={showRegisteredUserModal && !loader}
        icon={<Icons.UserAlert />}
        closeButton
        onClose={handleActionRegisteredUser}
        onAction={handleActionRegisteredUser}
        modalType="confirmation"
        title={i18n('CUSTOMERS__EXISTING_USER__MODAL__TITLE')}
        actionLabel={i18n('COMMONS__CLOSE__TEXT')}
        message={i18n('CUSTOMERS__EXISTING_USER__MODAL__MESSAGE')}
      />

      <ActionModal
        open={showBannedModal && !loader}
        icon={<Icons.UserAlert />}
        closeButton
        onClose={handleActionBannedCustomer}
        onAction={handleActionBannedCustomer}
        modalType="confirmation"
        title={i18n('CUSTOMERS__BANNED_USER__MODAL__TITLE')}
        actionLabel={i18n('CUSTOMERS__BANNED_USER__MODAL__CLOSE__LABEL')}
        message={i18n('CUSTOMERS__BANNED_USER__MODAL__MESSAGE')}
      />

      {avalCustomer && (
        <ActionModal
          open={showUnavailableJuridicalCustomer && !loader}
          icon={<Icons.UserAlert />}
          closeButton
          onClose={handleActionUnavailableJuridicalCustomer}
          onAction={handleActionUnavailableJuridicalCustomer}
          modalType="confirmation"
          title={i18n(
            'CUSTOMERS__UNAVAILABLE_JURIDICAL_CUSTOMER__MODAL__TITLE'
          )}
          actionLabel={i18n(
            'CUSTOMERS__UNAVAILABLE_JURIDICAL_CUSTOMER__MODAL__CLOSE__LABEL'
          )}
          message={i18n(
            'CUSTOMERS__UNAVAILABLE_JURIDICAL_CUSTOMER__MODAL__MESSAGE'
          )}
        />
      )}

      {action === ADD && !onlyFisic && (
        <div className="row">
          <div className="col-12 mt-1">
            <p className="text-muted">
              {i18n('CUSTOMERS__CUSTOMER_TYPES', ['*'])}
            </p>
          </div>
          <div className="col-12 mt-0 mb-3">
            <Button
              outline
              muted={isForeigner}
              secondary
              rounded
              className="mr-2"
              onClick={handleClickNational}
            >
              NACIONAL
            </Button>
            <Button
              outline
              muted={!isForeigner}
              secondary
              rounded
              className="mr-2"
              onClick={handleClickForeigner}
            >
              EXTRANJERO
            </Button>
          </div>

          {!minimal && !isForeigner && radioRender}
        </div>
      )}

      <Section title={title} className={sectionClass}>
        {disabledForeigner && (
          <div className="row">
            {!minimal && action === ADD && (
              <>
                <div className="col-4">
                  <Input
                    mask={alphaMask(18)}
                    maskPlaceholder=""
                    link={action === 'add' ? GOB_CURP : ''}
                    linkText={action === 'add' ? 'Consultar CURP' : ''}
                    label={i18n('CUSTOMERS__CURP__LABEL')}
                    placeholder={i18n('CUSTOMERS__CURP__LABEL')}
                    name="curp"
                    disabled={isForeigner && !onlyFisic}
                    upperCase
                  />
                </div>
                <div className="col-2 pt-2">
                  <br />
                  <Button block onClick={handleValidateCurp}>
                    {i18n('CUSTOMERS__CURP__SEARCH_BUTTON')}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
        {isTender && (
          <>
            {action === ADD ? (
              <div className="col-2">
                <RadioGroupInput
                  labelClass="d-flex align-items-center"
                  groupClass="col-12 d-flex justify-content-between"
                  parentClass="vehicle-radio-input"
                  label=""
                  name="tenderType"
                  options={VALUES_LIST_TENDER}
                  disabled={isView}
                  standardError
                  onChange={handleRadioTenderChange}
                />
              </div>
            ) : (
              <div className="CustomerPodium__profile-title">
                <h3 className="CustomerPodium__profile-subtitle">
                  {tenderType
                    ? `Licitación - ${
                        tenderType === CUSTOMER_TYPES.FISIC ? 'Física' : 'Moral'
                      }`
                    : 'Licitación'}
                </h3>
              </div>
            )}
            <div className="row">
              <div className="col-4">
                <ConnectedSelect
                  disabled={action === 'view'}
                  label="Sector*"
                  name="customerSector"
                  options="commons.customerSectorCatalog"
                  onChange={handleOnChangeCustomerSector}
                />
              </div>
            </div>
          </>
        )}

        <div className="row">
          {!minimal && !isForeigner && action === EDIT && false && radioRender}
        </div>

        <div className="row">
          {action !== ADD && !isForeigner && !isTender && (
            <div className="col-4">
              <Input
                mask={alphaMask(18)}
                maskPlaceholder=""
                link={GOB_CURP}
                linkText={action === ADD ? 'Consultar CURP' : ''}
                label={i18n('CUSTOMERS__CURP__LABEL')}
                placeholder={i18n('CUSTOMERS__CURP__LABEL')}
                name="curp"
                disabled={isForeigner || action !== ADD}
                upperCase
              />
            </div>
          )}

          <div className="col-4">
            <Input
              mask={alphaMaskWithSpacesAndAccent(50)}
              maskPlaceholder=""
              label={i18n('CUSTOMERS__INFORMATION__NAME', [requiredField])}
              placeholder={i18n('CUSTOMERS__INFORMATION__NAME', [
                requiredField,
              ])}
              name="names"
              disabled={disabledInput}
            />
          </div>
          <div className="col-4">
            <Input
              mask={alphaMaskWithSpacesAndAccent(50)}
              maskPlaceholder=""
              label={i18n('CUSTOMERS__INFORMATION__LAST_NAME', [requiredField])}
              placeholder={i18n('CUSTOMERS__INFORMATION__LAST_NAME', [
                requiredField,
              ])}
              name="lastName"
              disabled={disabledInput}
            />
          </div>
          <div className="col-4">
            <Input
              mask={alphaMaskWithSpacesAndAccent(50)}
              maskPlaceholder=""
              label={i18n('CUSTOMERS__INFORMATION__SECOND_LAST_NAME', [''])}
              placeholder={i18n('CUSTOMERS__INFORMATION__SECOND_LAST_NAME', [
                '',
              ])}
              name="secondLastName"
              disabled={disabledInput}
            />
          </div>

          {!isTender && (
            <>
              <div className="col-md-4">
                <DatePickerV2
                  initialValue={initialValues.birthDate || values.birthDate}
                  initialDate={getPrevYear({ years: 21 })}
                  label={i18n('CUSTOMERS__INFORMATION__DATE_OF_BIRTH', [
                    requiredField,
                  ])}
                  placeholder={i18n('CUSTOMERS__INFORMATION__DATE_OF_BIRTH', [
                    requiredField,
                  ])}
                  name="birthDate"
                  disabled={disabledInput}
                  minimumDate={getPrevYear({ years: 90 })}
                  maximumDate={getPrevYear({ years: 21 })}
                />
              </div>
              <div className="col-md-4">
                {!isForeigner || avalCustomer ? (
                  <Input
                    maskPlaceholder=""
                    label="Lugar de nacimiento"
                    placeholder="Lugar de nacimiento"
                    name="birthPlace"
                    disabled
                  />
                ) : (
                  <ConnectedSelect
                    label={originCountryLabel}
                    name={originCountryName}
                    options={originCountryOptions}
                    disabled={disabledInput}
                  />
                )}
              </div>
              <div className="col-md-4">
                <ConnectedSelect
                  label={i18n('CUSTOMERS__INFORMATION__GENDER', [
                    requiredField,
                  ])}
                  name="gender"
                  options="commons.sexes"
                  disabled={disabledInput}
                />
              </div>
            </>
          )}

          <div className="col-4">
            <Input
              mask={numericMask(10)}
              maskPlaceholder=""
              label={i18n('CUSTOMERS__INFORMATION__PHONE_NUMBER', ['*'])}
              placeholder={i18n('CUSTOMERS__INFORMATION__PHONE_NUMBER', ['*'])}
              name="phoneNumber"
              disabled={isView}
            />
          </div>
          {!isForeigner && (
            <div className="col-4">
              <Input
                mask={numericMask(10)}
                maskPlaceholder=""
                label="Número de casa u oficina"
                placeholder="Número de casa u oficina"
                name="homeNumber"
                disabled={isView}
              />
            </div>
          )}
          <div className="col-4">
            <Input
              mask={alphaEmailMask(50)}
              maskPlaceholder=""
              label={i18n('CUSTOMERS__INFORMATION__EMAIL', ['*'])}
              placeholder={i18n('CUSTOMERS__INFORMATION__EMAIL', ['*'])}
              name="email"
              disabled={isView}
            />
          </div>
        </div>
      </Section>
    </>
  );
};

CustomerInformation.propTypes = {
  action: PropTypes.string,
  minimal: PropTypes.bool,
  nextPath: PropTypes.string,
  isReservation: PropTypes.bool,
  sectionClass: PropTypes.string,
  onlyFisic: PropTypes.bool,
  getDetail: PropTypes.func,
  isForeigner: PropTypes.bool,
  customerFormValues: PropTypes.object,
  avalCustomer: PropTypes.bool,
  setAvalPreventChange: PropTypes.func,
  setFormValues: PropTypes.func,
  setSelectionOfAvalCustomerByDetail: PropTypes.func,
  selectionOfAvalCustomerByDetail: PropTypes.bool,
  setAvalCustomerByDetail: PropTypes.func,
};

CustomerInformation.defaultProps = {
  action: 'add',
  minimal: false,
  nextPath: '/customers/<%= id %>/detail?contract=true',
};

export default CustomerInformation;
