import withReservationAuth from '@hocs/withReservationAuth';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { withForm, Checkbox } from '@casanova/casanova-common';
import { Section } from 'components';
import {
  CardMethod,
  DepositMethod,
  CashMethod,
  TransferMethod,
  InvoicePayment,
} from '@reservations/sections';
import { number2mxn } from 'utils/currency';
import i18n from '@i18n';
import { scrollTop } from 'utils/screen';
import config from './config';
import './Payment.scss';
import _isEmpty from 'lodash/isEmpty';

const PaymentExtraCharges = ({
  match,
  reservationInfo = {},
  fetchCatalog,
  getCustomer,
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
  customerJuridical,
  priceExtraCharge,
  customerRefund,
}) => {
  const { naturalPerson = {}, folio = '' } = reservationInfo;

  const [hasUpdatedHoldBack, setHasUpdatedHoldBack] = useState(false);
  const [hasTotalPay, setHasTotalPay] = useState(0);
  const [hasUpdateDifMetods, setHasUpdateDifMetods] = useState(false);

  const { setFieldValue, errors, touched, values } = useFormikContext();

  const isJuridical = Boolean(customerJuridical);

  const hasRefund =
    !_isEmpty(customerRefund) && folio == customerRefund.reservationFolio;

  const totalAfterRefund = priceExtraCharge - customerRefund?.amount;

  const withoutPay = hasRefund && totalAfterRefund <= 0;

  useEffect(() => {
    setFieldValue('totallyPaidWithRefund', withoutPay);
  }, [withoutPay]);

  useEffect(() => {
    if (customerRefund?.amount && hasRefund) {
      setFieldValue('discount', customerRefund?.amount);
    }
  }, [customerRefund.amount, hasRefund]);

  useEffect(() => {
    setFieldValue('reservationId', match.params.id);
  }, [setFieldValue, match.params]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/banks',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-uses',
      catalogPath: 'pricequotes',
    });
    fetchCatalog('branches');
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });
  }, [fetchCatalog]);

  useEffect(() => {
    if (naturalPerson.uuid) {
      getCustomer(naturalPerson.uuid);
    }
  }, [getCustomer, naturalPerson?.uuid]);

  useEffect(() => {
    scrollTop();
    // Reset added billing details flag
    setHasAddedBillingDetailsOfPayment(false);

    return () => {
      setNewInvoicePayment(false);
      setHasAddedBillingDetailsOfPayment(false);
    };
  }, []);

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  useEffect(() => {
    const sumAmounts = sumaTotal();
    setFieldValue('totalPriceRent', priceExtraCharge);
    setHasTotalPay(sumAmounts);
    if (sumAmounts === priceExtraCharge) {
      // values.priceIsInvalid = true;
      setFieldValue('priceIsInvalid', true);
    } else {
      // values.priceIsInvalid = false;
      setFieldValue('priceIsInvalid', false);
    }
  }, [
    values.amountCard_0,
    values.amountCard_1,
    values.amountCard_2,
    values.amountCash,
    values.amountTransfer,
    values.amountDeposit,
  ]);

  function sumaTotal() {
    const total =
      Number(values.amountCard_0) +
      Number(values.amountCard_1) +
      Number(values.amountCard_2) +
      Number(values.amountCash) +
      Number(values.amountDeposit) +
      Number(values.amountTransfer);

    return total;
  }

  useEffect(() => {
    setFieldValue('diffMetods', hasUpdateDifMetods);
  }, [hasUpdateDifMetods]);

  const handleDifMetodsChange = () => {
    if (hasUpdateDifMetods) {
      setHasUpdateDifMetods(false);
      setHasTotalPay(0);
      values.amountCard_0 = '';
      values.amountCard_1 = '';
      values.amountCard_2 = '';
      values.amountCash = '';
      values.amountTransfer = '';
      values.amountDeposit = '';
      values.methodSelected = '';
      return;
    }
    setHasUpdateDifMetods(true);
  };

  return (
    <>
      <Section title="Formas de pago">
        <div className={withoutPay ? 'blur' : '' + 'relative'}>
          <div className={withoutPay ? 'disable' : ''}></div>
          <div className="payment-page__main__title my-4 d-flex justify-content-between align-items-center">
            <div>
              <Checkbox
                name="diffMetods"
                label="Pagar con diferentes métodos"
                onChange={handleDifMetodsChange}
              />
            </div>
            {hasUpdateDifMetods && <p>{number2mxn(hasTotalPay)}</p>}
          </div>
          <CardMethod
            setHasUpdatedHoldBack={setHasUpdatedHoldBack}
            hasUpdatedHoldBack={hasUpdatedHoldBack}
            hasUpdateDifMetods={hasUpdateDifMetods}
            uuidCustomer={naturalPerson.uuid}
          />
          {hasUpdateDifMetods &&
            [1, 2].map((index) => (
              <CardMethod
                setHasUpdatedHoldBack={setHasUpdatedHoldBack}
                hasUpdatedHoldBack={hasUpdatedHoldBack}
                hasUpdateDifMetods={hasUpdateDifMetods}
                index={index}
                uuidCustomer={naturalPerson.uuid}
              />
            ))}
          <DepositMethod hasUpdateDifMetods={hasUpdateDifMetods} />
          <TransferMethod hasUpdateDifMetods={hasUpdateDifMetods} />
          <CashMethod hasUpdateDifMetods={hasUpdateDifMetods} />
          <div className="disclaimer mt-3">
            <span className="disclaimer-title">
              {i18n('RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__TITLE')}
            </span>
            <span className="disclaimer-body">
              <span>
                {i18n(
                  'RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__DESCRIPTION'
                )}
              </span>
            </span>
          </div>
          <InvoicePayment />
        </div>
      </Section>

      <div className="row mt-4">
        <div className="col-12">
          {errors.methodSelected && touched.methodSelected && (
            <div className="col-form-error text-danger">
              {errors.methodSelected}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withReservationAuth(withForm({ config })(PaymentExtraCharges));
