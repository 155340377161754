import { useEffect, useState, useMemo, useRef } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import {
  Section,
  MaskedInput as Input,
  NumberInput,
  withForm,
  Table,
  Button,
} from '@casanova/casanova-common';
import {
  BackButton,
  TableBottom,
  PDFModal,
  BodyNotification,
} from 'components';
import { CreateCRP } from '@invoices';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import i18n from '@i18n';
import { fetchAndDownloadFileFromUrl } from 'services/commons';
import { generateUrlFromBase64Data } from 'utils/files';
import { APPLICATION_FILES } from 'utils/constants/filesExtensions';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { useFormikContext } from 'formik';

import columns from './columns';
import config from './config';
import './InvoiceDetail.scss';
import moment from 'moment';
import { getDocsInvoice } from '../../../services/catalogs/services';

function InvoiceDetail({
  action = FORM_ACTIONS.VIEW,
  listOfCrp,
  getInvoiceDetails,
  cleanInvoiceDetails,
  match,
  history,
  onUpdateForm,
  invoiceDetails,
  getListOfCRPs,
  setCRPsPage,
  reInvoice,
}) {
  const toast = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [hasFetchedWithDiffParams, setHasFetchedWithDiffParams] = useState(
    false
  );

  const { params } = listOfCrp;
  const contractNumber = useMemo(() => match.params.id, [match.params.id]);
  const prevParams = useMemo(() => params, []);

  const { values } = useFormikContext();

  const handleBack = () => {
    cleanInvoiceDetails();
    setTimeout(() => {
      history.push('/invoices/');
    }, 1);
  };

  const handleEmitCRP = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const onSelectPdf = (newPdfUrl) => {
    setPdfUrl(newPdfUrl);
    setShowPdfModal(true);
  };

  useEffect(() => {
    console.log('invoiceDetails', invoiceDetails);
  }, [invoiceDetails]);

  const copyToClipboard = (e) => {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'URL Copiada',
          sticky: false,
          detail:
            'Se ha copiado el URL correctamente, abra una nueva ventana para pegar la URL de la factura.',
          content: (props) => <BodyNotification {...props} />,
          position: 'top-center',
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowPdf = async () => {
    try {
      const { data } = await getDocsInvoice(
        invoiceDetails.folio,
        invoiceDetails.serie
      );
      const url = `data:application/pdf;base64,${data.Base64PDF}`;
      if (data.Procesada) {
        setPdfUrl(url);
        setShowPdfModal(true);
      } else
        toast.current.show({
          severity: 'error',
          summary: 'Facturación',
          sticky: false,
          detail: 'Esta factura aún no tiene un archivo disponible.',
          content: (props) => <BodyNotification {...props} />,
          position: 'top-center',
        });
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Facturación',
        sticky: false,
        detail:
          'Estamos experimentando intermitencia con SAP, vuelve a intentarlo de nuevo.',
        content: (props) => <BodyNotification {...props} />,
        position: 'top-center',
      });
    }
  };

  const handleClosePdf = () => {
    setShowPdfModal(false);
    setPdfUrl('');
  };

  const handleDownloadXML = async () => {
    const { data } = await getDocsInvoice(
      invoiceDetails.folio,
      invoiceDetails.serie
    );
    const url = `data:application/xml;base64,${data.Base64XML}`;
    if (data.Procesada) {
      const link = document.createElement('a');
      link.href = url;
      link.download = `FacturaNormal_${invoiceDetails.fiscalFolio}_${invoiceDetails.serie}_.xml`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else
      toast.current.show({
        severity: 'error',
        summary: 'Facturación',
        sticky: false,
        detail: 'Esta factura aún no tiene un archivo disponible.',
        content: (props) => <BodyNotification {...props} />,
        position: 'top-center',
      });

    // const fileName = `invoice_${invoiceDetails.uuid}`;
    // fetchAndDownloadFileFromUrl(url, 'xml', 'proof.xml');
  };

  useEffect(() => {
    if (_isEmpty(invoiceDetails) && !hasFetched) {
      getInvoiceDetails(contractNumber);
    } else {
      setHasFetched(true);
    }
  }, [getInvoiceDetails, contractNumber, hasFetched]);

  useEffect(() => {
    if (
      !_isEmpty(invoiceDetails) &&
      (!_isEqual(prevParams, params) || hasFetchedWithDiffParams)
    ) {
      getListOfCRPs({ invoiceUuid: invoiceDetails.uuid, params });
      setHasFetchedWithDiffParams(true);
    }
  }, [getListOfCRPs, params]);

  useEffect(() => {
    if (!_isEmpty(invoiceDetails)) {
      onUpdateForm(invoiceDetails);
    }
  }, [onUpdateForm, invoiceDetails]);

  const handleReInvoice = () => {
    const { contractUuid, serie, folio } = invoiceDetails;
    reInvoice({ contractUuid, serie, folio });
  };

  const isView = action === FORM_ACTIONS.VIEW;

  return (
    <>
      <Toast ref={toast} position="bottom-center" />
      {showModal && <CreateCRP show={showModal} onClose={handleClose} />}

      <PDFModal show={showPdfModal} onClose={handleClosePdf} url={pdfUrl} />

      <Section
        title={i18n('INVOICES__INVOICE_DETAIL')}
        className="InvoiceDetail"
      >
        <div className="row">
          <div className="col-md-4">
            <Input
              label={i18n('INVOICES__SERIE')}
              placeholder={i18n('INVOICES__SERIE')}
              maskPlaceholder=""
              name="series"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__FOLIO')}
              placeholder={i18n('COMMONS__FOLIO')}
              maskPlaceholder=""
              name="folio"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__RFC')}
              placeholder={i18n('COMMONS__RFC')}
              maskPlaceholder=""
              name="rfc"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__NAME')}
              placeholder={i18n('COMMONS__NAME')}
              maskPlaceholder=""
              name="customerName"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__DATE')}
              placeholder={i18n('COMMONS__DATE')}
              maskPlaceholder=""
              name="invoiceDate"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('INVOICES__AMOUNT')}
              placeholder={i18n('INVOICES__AMOUNT')}
              maskPlaceholder=""
              name="amount"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <NumberInput
              label={i18n('COMMONS__IVA')}
              placeholder={i18n('COMMONS__IVA')}
              name="vat"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <NumberInput
              label={i18n('COMMONS__TOTAL')}
              placeholder={i18n('COMMONS__TOTAL')}
              name="totalAmount"
              disabled={isView}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={i18n('COMMONS__STATUS')}
              placeholder={i18n('COMMONS__STATUS')}
              name="invoiceStatus"
              disabled={isView}
            />
          </div>
        </div>

        {invoiceDetails?.invoiceStatus?.name == 'Error' && (
          <div className="row my-4">
            <div className="col mr-2">
              <Message severity="error" text={values.error} />
            </div>
          </div>
        )}

        <div className="row my-4">
          <div className="col-3">
            <Button
              outline
              rounded
              block
              className="mr-2"
              onClick={handleShowPdf}
              disabled={invoiceDetails?.invoiceStatus?.name == 'Error'}
            >
              Ver PDF
            </Button>
          </div>
          <div className="col-4">
            <Button
              outline
              rounded
              block
              onClick={handleDownloadXML}
              disabled={invoiceDetails?.invoiceStatus?.name == 'Error'}
            >
              Descargar XML
            </Button>
          </div>
          {/* <div className='col-12'>
            <span className='col-form-info-text mt-1 d-block'>Haz click en el botón deseado y pega el contenido en una nueva pestaña para poder descargar.</span>
          </div> */}
        </div>

        {/* <div className="row">
          <div className="col-12">
            <Table
              rowClassName="row-clickable"
              emptyMessage="No se encontraron resultados"
              dataList={listOfCrp.results}
              columns={columns({ onSelectPdf, invoiceDetails })}
              withEllipsis
            />
          </div>
        </div>

        <TableBottom
          search={listOfCrp}
          onChange={setCRPsPage}
          showButton={false}
        />  */}

        <div className="row mt-4">
          <div className="col-md-4">
            <BackButton onClick={handleBack} />
          </div>
          <div className="col" />
          <div className="col-md-3">
            {/* <Button
              block
              className="PaymentButton mt-0"
              onClick={handleEmitCRP}
            >
              {i18n('INVOICES__EMIT_CRP')}
            </Button> */}
            {invoiceDetails?.invoiceStatus?.name == 'Error' && (
              <Button
                block
                className="PaymentButton mt-0"
                onClick={handleReInvoice}
              >
                Timbrar
              </Button>
            )}
          </div>
        </div>
      </Section>
    </>
  );
}

export default withForm({ config })(InvoiceDetail);
