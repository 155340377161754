import _get from 'lodash/get';

export const vehiclesTransformer = ({
  temporalChanges = [],
  vehicles = [],
}) => {
  if (vehicles.length === 0) {
    return { temporalChanges: [] };
  }

  const transformedVehicles = vehicles.map((vehicle) => {
    const vehicleRol =
      vehicles.length >= 1 ? 'Vehículo Actual' : 'Vehículo Único';
    //Solo si hay un vehiculo
    const transformedVehicle = {
      ...vehicle,
      brand: _get(vehicle, 'vehicleBrand', ''),
      line: _get(vehicle, 'vehicleLine', ''),
      model: _get(vehicle, 'model', ''),
      serialNumber: _get(vehicle, 'serialNumber', ''),
      plateNumber: _get(vehicle, 'plate', ''),
      status: _get(vehicle, 'status', ''),
      subStatus: _get(vehicle, 'subStatus', ''),
      version: _get(vehicle, 'version', ''),
      vehicleRol: vehicleRol,
      vehicleUuid: _get(vehicle, 'uuid'),
      primaryVehicle: _get(vehicle, 'primaryVehiclePlate', ''),
      replaceVehicle: _get(
        vehicle,
        'previousVehiclePlate',
        'Sin sustituto'
      ),
    };

    return transformedVehicle;
  });

  // Recorrer temporalChanges para transformar vehículos anteriores
  const transformedTemporalChanges = temporalChanges.reduce((acc, change) => {
    const vehicleA = {
      brand: _get(change, 'vehicleA.vehicleBrand', ''),
      line: _get(change, 'vehicleA.vehicleLine', ''),
      model: _get(change, 'vehicleA.model', ''),
      serialNumber: _get(change, 'vehicleA.serialNumber', ''),
      plateNumber: _get(change, 'vehicleA.plate', ''),
      status: _get(change, 'vehicleA.status', ''),
      subStatus: _get(change, 'vehicleA.subStatus', ''),
      version: _get(change, 'vehicleA.version', ''),
      vehicleRol: 'Vehículo Anterior',
      vehicleUuid: _get(change, 'vehicleA.vehicleUuid'),
      primaryVehicle: _get(change, 'vehicleA.primaryVehiclePlate', ''),
      replaceVehicle: _get(
        change,
        'vehicleA.previousVehiclePlate',
        'Sin sustituto'
      ),
    };

    const vehicleB = {
      brand: _get(change, 'vehicleB.vehicleBrand', ''),
      line: _get(change, 'vehicleB.vehicleLine', ''),
      model: _get(change, 'vehicleB.model', ''),
      serialNumber: _get(change, 'vehicleB.serialNumber', ''),
      plateNumber: _get(change, 'vehicleB.plate', ''),
      status: _get(change, 'vehicleB.status', ''),
      subStatus: _get(change, 'vehicleB.subStatus', ''),
      version: _get(change, 'vehicleB.version', ''),
      vehicleRol: 'Vehículo Anterior',
      vehicleUuid: _get(change, 'vehicleB.vehicleUuid'),
      primaryVehicle: _get(change, 'vehicleB.primaryVehiclePlate', ''),
      replaceVehicle: _get(change, 'vehicleB.previousVehiclePlate', 'Sin sustituto'),
    };    

    acc.push(vehicleA, vehicleB);
    return acc;
  }, []);

  // Combinar transformedVehicles y transformedTemporalChanges
  const allTransformedVehicles = [
    ...transformedVehicles,
    ...transformedTemporalChanges,
  ];

  // Eliminar duplicados basados en vehicleUuid
  const uniqueTransformedVehicles = allTransformedVehicles.filter(
    (vehicle, index, self) =>
      index === self.findIndex((v) => v.vehicleUuid === vehicle.vehicleUuid)
  );

  return {
    temporalChanges: uniqueTransformedVehicles,
  };
};
