import _get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/es';

import store from 'store';

import { YYYY_MM_DD } from 'utils/constants';
import { removeSpaces } from '@casanova/casanova-common/lib/utils/masks';

moment.locale('es');

export const formatCard = (values, index = 0) => {
  const fields = [
    'authorization',
    'cardType',
    'hasHolderName',
    'cardNumber',
    'reference',
    'movementDate',
    'movementHour',
    'voucher',
    'accountStatus',
    'bank',
    'amountCard',
  ];

  const extractedValues = fields.reduce((acc, field) => {
    acc[field] = values[`${field}_${index}`];
    return acc;
  }, {});

  extractedValues.diffMetods = values.diffMetods;

  const {
    authorization,
    cardType,
    hasHolderName,
    cardNumber,
    reference,
    movementDate,
    movementHour,
    voucher,
    accountStatus,
    bank,
    diffMetods,
    amountCard,
  } = extractedValues;

  const cardTypes = _get(
    store.getState(),
    'commons.catalogsBankCardTypes.list',
    []
  );

  const fullCardType = cardTypes.find(
    (card) => card.uuid === values[`cardType_${index}`]
  );

  const cardDetails = {
    bank,
    bankAuthorization: authorization,
    bankCardType: cardType,
    cardHasCustomerName: hasHolderName,
    cardNumber,
    referenceNumber: reference,
  };

  const paymentForm = `${fullCardType?.identifier}_CARD_${index}`;

  if (diffMetods) {
    return {
      paymentForm,
      cardDetails,
      paymentDate: moment(movementDate, YYYY_MM_DD).format(YYYY_MM_DD),
      paymentTime: movementHour,
      files: {
        paymentProof: voucher,
        accountStatus: !hasHolderName ? accountStatus : null,
      },
      isTheLastPayment: diffMetods,
      amount: amountCard,
    };
  }

  return {
    paymentForm,
    cardDetails,
    paymentDate: moment(movementDate, YYYY_MM_DD).format(YYYY_MM_DD),
    paymentTime: movementHour,
    files: {
      paymentProof: voucher,
      accountStatus: !hasHolderName ? accountStatus : null,
    },
  };
};

export const formatDeposit = (values) => {
  const {
    bankAuthorization,
    bankDate,
    bankHour,
    bankVoucher,
    diffMetods,
    amountDeposit,
  } = values;
  const depositDetails = {
    bankAuthorization,
  };

  if (diffMetods) {
    return {
      paymentForm: 'DEPOSIT',
      depositDetails,
      paymentDate: moment(bankDate, YYYY_MM_DD).format(YYYY_MM_DD),
      paymentTime: bankHour,
      files: {
        paymentProof: bankVoucher,
      },
      isTheLastPayment: diffMetods,
      amount: amountDeposit,
    };
  }
  return {
    paymentForm: 'DEPOSIT',
    depositDetails,
    paymentDate: moment(bankDate, YYYY_MM_DD).format(YYYY_MM_DD),
    paymentTime: bankHour,
    files: {
      paymentProof: bankVoucher,
    },
  };
};

export const formatTransfer = (values) => {
  const {
    bankTo,
    bankClave,
    bankReference,
    transferHour,
    transferDate,
    transferVoucher,
    diffMetods,
    amountTransfer,
  } = values;
  const transferDetails = {
    bank: bankTo,
    referenceNumber: bankReference,
    trackingKey: bankClave,
  };

  if (diffMetods) {
    return {
      paymentForm: 'TRANSFER',
      transferDetails,
      paymentDate: moment(transferDate, YYYY_MM_DD).format(YYYY_MM_DD),
      paymentTime: transferHour,
      files: {
        paymentProof: transferVoucher,
      },
      isTheLastPayment: diffMetods,
      amount: amountTransfer,
    };
  }
  return {
    paymentForm: 'TRANSFER',
    transferDetails,
    paymentDate: moment(transferDate, YYYY_MM_DD).format(YYYY_MM_DD),
    paymentTime: transferHour,
    files: {
      paymentProof: transferVoucher,
    },
  };
};

const formatCommons = (values) => {
  const {
    holdbackRetention,
    holdbackOtherRetention,
    holdbackCardHolder,
    holdbackBank,
    holdbackCard,
    holdbackCode,
    holdbackValidity,
    reservationId,
  } = values;

  const elements = holdbackValidity.split('/');
  const month = parseInt(elements[0], 10);
  const year = parseInt(elements[1], 10) + 2000;

  const realMonth = month >= 10 ? month : `0${month}`;

  const retention = holdbackRetention.toLowerCase().includes('otro')
    ? parseFloat(holdbackOtherRetention)
    : parseFloat(holdbackRetention);

  const creditCardHold = {
    bank: holdbackBank,
    cardHolderName: holdbackCardHolder,
    cardNumber: removeSpaces(holdbackCard),
    cvc: holdbackCode,
    expDate: `${year}-${realMonth}-01`,
    amountWithheld: retention,
  };

  return {
    reservation: reservationId,
    creditCardHold,
    paymentType: 'CONTRACT',
  };
};

export const formatInvoice = (values) => {
  const {
    invoiceSocialReason,
    invoiceRfc,
    invoiceCfdiUse,
    isNewInvoice,
    invoiceDeleted,
    emailInvoice,
    invoicePayForm,
    invoicePayMethod,
    observationsInvoice,
    invoiceSelected,
    invoiceZipCode,
    invoiceTaxRegime,
  } = values;

  return {
    invoiceForm: {
      invoiceSocialReason,
      invoiceRfc,
      invoiceCfdiUse,
      isNewInvoice,
      emailInvoice,
      invoicePayForm,
      invoicePayMethod,
      observationsInvoice,
      invoiceSelected,
      invoiceZipCode,
      invoiceTaxRegime,
    },
    invoiceDeleted,
  };
};

const formatter = (values) => {
  let request = {};

  // switch (values.methodSelected) {
  //   case 'card':
  //     request = formatCard(values);
  //     break;
  //   case 'deposit':
  //     request = formatDeposit(values);
  //     break;
  //   case 'transfer':
  //     request = formatTransfer(values);
  //     break;
  //   default:
  //     request = {
  //       paymentForm: 'CASH',
  //     };
  //     break;
  // }

  const multiRequest = [];

  if (values.diffMetods) {
    const amountCardKeys = Object.keys(values).filter((key) =>
      key.startsWith('amountCard_')
    );

    amountCardKeys.forEach((key) => {
      const match = key.match(/amountCard_(\d+)/);
      if (match && values[key]) {
        const index = match[1];
        request = formatCard(values, index);
        multiRequest.push(request);
      }
    });
    // if (values.amountCard) {
    //   request = formatCard(values);
    //   multiRequest.push(request);
    // }
    if (values.amountDeposit) {
      request = formatDeposit(values);
      multiRequest.push(request);
    }
    if (values.amountTransfer) {
      request = formatTransfer(values);
      multiRequest.push(request);
    }
    if (values.amountCash) {
      request = {
        paymentForm: 'CASH',
        isTheLastPayment: values.diffMetods,
        amount: values.amountCash,
      };
      multiRequest.push(request);
    }
  } else {
    switch (values.methodSelected) {
      case 'card_0':
      case 'card_1':
      case 'card_2':
      case 'card':
        request = formatCard(values, values.methodSelected.split('_')[1]);
        break;
      case 'deposit':
        request = formatDeposit(values);
        break;
      case 'transfer':
        request = formatTransfer(values);
        break;
      default:
        request = {
          paymentForm: 'CASH',
        };
        break;
    }
  }

  const commons = formatCommons(values);
  if (values.wantInvoice) {
    if (multiRequest.length > 0) {
      multiRequest.forEach((element) => {
        if (element.paymentForm !== request.paymentForm) {
          element.discount = 0;
          element.isTheLastPayment = false;
        }
        element.paymentForm = element.paymentForm.replace(/_\d+$/, '');
      });
      multiRequest[multiRequest.length - 1].discount = 0;
      return {
        multiRequest,
        ...formatInvoice(values),
        commons,
      };
    }

    request.paymentForm = request.paymentForm.replace(/_\d+$/, '');
    request.isTheLastPayment = true;
    request.discount = values.discount;
    return {
      ...request,
      ...formatInvoice(values),
      ...commons,
    };
  }

  if (multiRequest.length > 0) {
    multiRequest.forEach((element) => {
      if (element.paymentForm !== request.paymentForm) {
        element.isTheLastPayment = false;
        element.discount = values.discount;
      }
      element.paymentForm = element.paymentForm.replace(/_\d+$/, '');
    });
    multiRequest[multiRequest.length - 1].discount = values.discount;
    return { multiRequest, commons };
  }
  request.paymentForm = request.paymentForm.replace(/_\d+$/, '');
  request.isTheLastPayment = true;
  request.discount = values.discount;
  return { ...request, ...commons };
};

export default formatter;
